import { Typography, Box, Container, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

import PersonRelaxing from "../../static/img/person-relaxing.png";
import ChetwoodBank from "../../static/img/chetwood-bank.svg";

const commonStyles = {
    fontBase: {
        fontFamily: "DM Sans",
        fontWeight: "500",
        color: "#003232"
    }
};

const useStyles = makeStyles({
    title: {
        ...commonStyles.fontBase,
        fontSize: "3.625rem",
        lineHeight: "110%",
        "@media (max-width: 768px)": {
            fontSize: "2.125rem",
            lineHeight: "120%"
        }
    },
    tagline: {
        ...commonStyles.fontBase,
        fontSize: "1.875rem"
    },
    button: {
        ...commonStyles.fontBase,
        fontSize: "1.25rem",
        padding: "14px 25px",
        borderRadius: "4px",
        backgroundColor: "#D14600",
        color: "#fff",
        "@media (max-width: 1280px)": {
            padding: "12px 20px"
        },
        "@media (max-width: 576px)": {
            width: "100%"
        },
        "&:hover": {
            backgroundColor: "#BC3F00",
            boxShadow:
                "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)"
        }
    },
    footer: {
        ...commonStyles.fontBase,
        fontSize: "0.875rem",
        "@media (max-width: 1280px)": {
            fontSize: "0.75rem"
        },
        fontWeight: "300",
        color: "#201A1C",
        lineHeight: "135%",
        "& a": {
            color: "#201A1C",
            textDecoration: "underline"
        }
    },
    personRelaxingImage: {
        "@media (min-width: 1280px)": {
            position: "relative",
            top: "-100px",
            right: "0"
        },
        maxWidth: "100vw"
    }
});

const IndexPage = () => {
    const classes = useStyles();

    return (
        <Box bgcolor="#F5F5F0" pt="5%" height="100vh" overflow="auto">
            <Container>
                <Box
                    mb={2}
                    sx={{
                        maxWidth: {
                            xs: "80%",
                            sm: "50%",
                            lg: "50%"
                        }
                    }}
                >
                    <Typography variant="h1" className={classes.title}>
                        Chetwood Financial is now{" "}
                        <span style={{ fontWeight: "700" }}>Chetwood Bank</span>
                    </Typography>
                </Box>

                <Box
                    mb={5}
                    sx={{
                        maxWidth: {
                            xs: "80%",
                            sm: "65%",
                            lg: "60%"
                        }
                    }}
                >
                    <Typography className={classes.tagline}>
                        Make sense of your money with no nonsense banking.
                    </Typography>
                </Box>

                <Box
                    display="flex"
                    sx={{
                        flexDirection: {
                            xs: "column-reverse",
                            sm: "column"
                        }
                    }}
                >
                    <Box mb={4}>
                        <Button className={classes.button} href="https://chetwoodbank.co.uk">
                            Go to Chetwood Bank
                        </Button>
                    </Box>

                    <Box
                        display="flex"
                        justifyContent="right"
                        sx={{
                            justifyContent: {
                                xs: "center",
                                lg: "right"
                            }
                        }}
                    >
                        <img src={PersonRelaxing} className={classes.personRelaxingImage} alt="" />
                    </Box>
                </Box>

                <img src={ChetwoodBank} alt="Chetwood Bank" />
                <Box mt={2} py={2} borderTop="1px solid #335B5B">
                    <Typography className={classes.footer}>
                        Chetwood Bank, ModaMortgages, SmartSave, BetterBorrow and LiveLend are
                        trading names of Chetwood Financial Limited, company number 09964966.
                        Chetwood Financial Limited is authorised by the Prudential Regulation
                        Authority and regulated by the Financial Conduct Authority and the
                        Prudential Regulation Authority, under registration number 740551.
                        Authorisation can be checked on the Financial Services Register at{" "}
                        <a href="https://www.fca.org.uk" target="_blank" rel="noopener noreferrer">
                            www.fca.org.uk
                        </a>{" "}
                        We’re also registered with the Information Commissioner’s Office under
                        registration ZA218401 and we’re a member of CIFAS, the UK’s leading fraud
                        prevention service. Chetwood Financial Limited is registered in England and
                        Wales and our offices are at Ellice Way, Wrexham Technology Park, Wrexham,
                        LL13 7YT. CHL Mortgages is used under licence by CHL Mortgages for
                        Intermediaries Limited. Registered office: Admiral House, Harlington Way,
                        Fleet, Hampshire, United Kingdom, GU51 4YA (Company No 12954007).
                    </Typography>
                    <Box mt={1.25}>
                        <Typography className={classes.footer}>
                            Copyright © 2024. Chetwood Bank. All rights reserved.
                        </Typography>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

export default IndexPage;
